import {useSession} from 'next-auth/react';
import {createContext, useContext, useEffect, useRef, useState} from 'react';
import {useRouter} from 'next/router';
import {useLogoutV2} from '@components/accounts/useLoginLogoutV2';
import {useAccountAddress} from '@components/accounts/hooks/account-address';
import {useAccountCards} from '@components/accounts/hooks/account-cards';
import {useAccountMarketing} from '@components/accounts/hooks/account-marketing';
import {useAccountOrders} from '@components/accounts/hooks/account-orders';
import {useAccountData} from '@components/accounts/hooks/account-data';
import {useAccountRmas} from '@components/accounts/hooks/account-rmas';
import {filterOutUndeliveredItemsFromItemsToReview} from '@components/accounts/AccountItemReviews';

export const AccountContext = createContext(null);

export const AccountProvider = ({children, ...props}) => {
  const page = props?.page;
  const initialAccountData = props?.accountData;

  const router = useRouter();
  const {data: session, status, update: updateSession} = useSession();
  const {isLoggingOut} = useLogoutV2();
  const {handleEmail, handleSms} = useAccountMarketing(updateSession);

  const [currentPage, setCurrentPage] = useState(page);
  const [accountData, setAccountData] = useState(initialAccountData);

  const accountDataRef = useRef(accountData);

  useEffect(() => {
    if (status === 'unauthenticated' && status !== 'loading') {
      router.push(`/account/login?callbackUrl=${encodeURIComponent(router?.asPath)}`);
    }
  }, [status]);

  let currentOrderNumber = null;
  if (currentPage?.startsWith('orders')) {
    const hasOrderNumberInPath = currentPage.includes('/');
    if (hasOrderNumberInPath) {
      currentOrderNumber = currentPage.split('/').pop();
    }
  }

  let currentRmaNumber = null;
  if (currentPage?.startsWith('returns/RMA-')) {
    const hasRmaNumberInPath = currentPage.includes('/');
    if (hasRmaNumberInPath) {
      currentRmaNumber = currentPage.split('/').pop();
      currentRmaNumber = decodeURIComponent(currentRmaNumber);
    }
  }

  let {data, mutate, isValidating, isLoading} = useAccountData(accountDataRef.current);
  // console.log('[AccountProvider][accountData]', data);

  const {
    addresses,
    addressBeingEdited,
    handleSaveAddress,
    handleNewAddress,
    handleEditAddress,
    handleCancelEditAddress,
    handleDeleteAddress
  } = useAccountAddress(data, mutate);

  const {
    cards,
    cardBeingEdited,
    setCardBeingEdited,
    handleNewCard,
    handleEditCard,
    handleSaveCard,
    handleCancelEditCard,
    handleDeleteCard
  } = useAccountCards(data, mutate);

  const {
    order,
    isLoadingOrder,
    orders,
    hasPrevOrders,
    hasNextOrders,
    ordersPrev,
    ordersNext,
    ordersLoading,
    ordersValidating,
    ordersMutate
  } = useAccountOrders(data, currentOrderNumber);

  const {rmas, rma, isLoadingRma, rmaError} = useAccountRmas(data, currentRmaNumber);

  useEffect(() => {
    let newPath = router?.asPath;
    newPath = newPath?.replace(/\/account\/?/g, '');
    if (!newPath || newPath === '') {
      newPath = 'home';
    }
    if (currentPage !== newPath) {
      setCurrentPage(newPath);
    }
  }, [router]);

  useEffect(() => {
    // console.log({status});
    if (status === 'unauthenticated') {
      accountDataRef.current = null;
      setAccountData(null);
    }

    if (status === 'authenticated') {
      handleMutateAll(accountData);
    }
  }, [status]);

  useEffect(() => {
    if (data) {
      setAccountData(data);
      accountDataRef.current = data;
    }
  }, [data]);

  const isLoggedIn = session && !!session?.user?.email ? true : false;

  const handleMutateAll = async (accountData) => {
    await mutate(accountData, {revalidate: false});
    await ordersMutate({data: accountData?.orders}, {revalidate: false});
  };

  const handleSwrClearData = async () => {
    await mutate(null, {revalidate: false});
    await ordersMutate(null, {revalidate: false});
  };

  const handleGoToAccountHome = (newPage) => {
    setCurrentPage('home');
    router.push(`/account`, undefined, {shallow: true});
  };

  const handleGoToOrders = (newPage) => {
    setCurrentPage('orders');
    router.push(`/account/orders`, undefined, {shallow: true});
  };

  const handleGoToReturns = (newPage) => {
    setCurrentPage('returns');
    router.push(`/account/returns`, undefined, {shallow: true});
  };

  const itemsWithReviews = data?.itemsWithReviews;
  let itemsWithoutReviews = data?.itemsWithoutReviews;
  itemsWithoutReviews = filterOutUndeliveredItemsFromItemsToReview(itemsWithoutReviews, orders);
  // console.log('[AccountProvider] itemsWithoutReviews:', itemsWithoutReviews);

  const providerValues = {
    session,
    isLoggedIn,
    isLoggingOut,
    currentPage,
    setCurrentPage,
    handleGoToAccountHome,
    accountData: data,
    mutateAccountData: mutate,
    handleSwrClearData,
    orders,
    order,
    isLoadingOrder,
    currentOrderNumber,
    ordersMutate,
    hasPrevOrders,
    hasNextOrders,
    ordersPrev,
    ordersNext,
    ordersLoading,
    ordersValidating,
    handleGoToOrders,
    rmas,
    rma,
    isLoadingRma,
    rmaError,
    currentRmaNumber,
    handleGoToReturns,
    addresses,
    addressBeingEdited,
    handleSaveAddress,
    handleNewAddress,
    handleEditAddress,
    handleCancelEditAddress,
    handleDeleteAddress,
    cards,
    cardBeingEdited,
    setCardBeingEdited,
    handleNewCard,
    handleEditCard,
    handleSaveCard,
    handleDeleteCard,
    handleCancelEditCard,
    handleEmail,
    handleSms,
    itemsWithReviews,
    itemsWithoutReviews,
    accountDataLoading: isLoading,
    accountDataLoaded: typeof data !== 'undefined',
    accountDataValidating: isValidating
  };

  return <AccountContext.Provider value={providerValues}>{children}</AccountContext.Provider>;
};

export const useAccountProvider = () => {
  try {
    const context = useContext(AccountContext);
    if (!context) {
      throw new Error('useAccountProvider must be used within a AccountProvider');
    }
    return context;
  } catch (error) {
    console.error('An error occurred in useAccountProvider:', error);
  }
};
