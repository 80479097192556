import {AccountOrdersBlockOrderItem} from '@components/accounts/ui/AccountOrdersBlock.jsx';
import {AccountPageBreadcrumbs} from '@components/accounts/ui/Common';
import {useAccountProvider} from '@components/accounts/AccountProvider';

export const AccountsItemReviews = () => {
  const {
    itemsWithReviews,
    itemsWithoutReviews,
    handleGoToAccountHome
  } = useAccountProvider();
  console.log('[AccountProvider] itemsWithoutReviews:', itemsWithoutReviews);
  const itemsWithoutReviewCount = itemsWithoutReviews?.length || 0;
  const itemsWithReviewsCount = itemsWithReviews?.length ?? 0;
  const hasItemsWithoutReviews = itemsWithoutReviewCount > 0;
  const hasItemsWithReviews = itemsWithReviewsCount > 0;
  const hasNoItemsWithoutReviews = itemsWithoutReviewCount === 0;
  // console.log("[AccountsItemReviews] hasItemsWithoutReviews:", hasItemsWithoutReviews);
  // console.log("[AccountsItemReviews] hasItemsWithReviews:", hasItemsWithReviews);
  // console.log("[AccountsItemReviews] itemsWithoutReviews:", itemsWithoutReviews);

  let breadcrumbs = [
    {
      label: 'Your Account',
      href: '/account',
      onClick: handleGoToAccountHome
    },
    {
      label: 'Item Reviews'
    }
  ];

  return (
    <>
      <AccountPageBreadcrumbs breadcrumbs={breadcrumbs} />

      <div className="relative flex-grow">
        <div>
          <h2 className="text-xl uppercase pb-2 font-oswald">
            Items Reviewed ({itemsWithReviewsCount})
          </h2>
          <div className={'my-2'}>
            {hasItemsWithReviews &&
              itemsWithReviews?.map((item) => {
                return (
                  <div key={`reviewed-${item?.orderItemId}`} className="border-b mb-6 pb-6">
                    <AccountOrdersBlockOrderItem item={item} showReviewButton={false}
                                                 disableLinkProdImage={true} />
                  </div>
                );
              })}
          </div>

          <div>d
            <h2 className="text-xl uppercase pb-2 font-oswald fw-bold">
              Items to review ({itemsWithoutReviewCount})
            </h2>
            <div className={'my-2'}>
              {hasNoItemsWithoutReviews && (
                <div className="flex h-10 items-center italic">
                  You have either reviewed all purchased items or items purchased cannot be reviewed
                  until they are delivered.
                </div>
              )}

              {hasNoItemsWithoutReviews && itemsWithReviewsCount === 0 && (
                <div className="flex h-10 items-center italic">
                  You don't have any items to review yet.
                </div>
              )}

              {hasItemsWithoutReviews &&
                itemsWithoutReviews?.map((item) => {
                  return (
                    <div key={`toreview-${item?.orderItemId}`} className="border-b mb-3 pb-3">
                      <AccountOrdersBlockOrderItem item={item} disableLinkProdImage={true} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export const filterOutUndeliveredItemsFromItemsToReview = (itemsWithoutReviews, orders) => {
  if (!itemsWithoutReviews?.length) return []; // do not change
  if (!orders?.length) return itemsWithoutReviews; // do not change
  // console.log('[filterItemsToReview] itemsWithoutReviews:', itemsWithoutReviews);
  // console.log('[filterItemsToReview] orders:', orders);

  const itemsToReview = itemsWithoutReviews?.filter(item => {
    const matchingOrder = orders?.find(order => order?.orderNumber === item?.order_number);

    if (!matchingOrder) {
      console.log(`No matching order found for item with order number ${item?.order_number}`);
      return false;
    }

    const matchingShipment = matchingOrder?.shipments?.find(shipment =>
      shipment?.items?.some(shipmentItem => shipmentItem?.sku === item?.sku)
    );

    const isDelivered = matchingShipment && matchingShipment?.is_delivered;
    const noReview = item?.review_rating === null && item?.review_title === null && item?.review_body === null;
    return isDelivered && noReview;
  });

  // console.log('[filterItemsToReview] itemsToReview:', itemsToReview);
  return itemsToReview;
};
